<template>
  <v-row>
    <v-col cols="3" md="3"
      style="max-height: 600px"
      class="overflow-y-auto"
    >
      <v-card class="pa-2" outlined tile>
        Pengaturan Tanggal Klaim BPJS
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-menu
            v-model="showDateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field label="Dari Tanggal" readonly :value="tanggal" v-on="on"></v-text-field>
            </template>
            <v-date-picker
              locale="en-in"
              v-model="tanggal"
              no-title
              @input="showDateMenu = false"
            ></v-date-picker>
          </v-menu>

          <v-menu
            v-model="showDateMenu2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field label="Sampai Tanggal" readonly :value="tanggalAkhir" v-on="on"></v-text-field>
            </template>
            <v-date-picker
              locale="en-in"
              v-model="tanggalAkhir"
              no-title
              @input="showDateMenu2 = false"
            ></v-date-picker>
          </v-menu>
          <v-btn :disabled="!valid" color="primary" class="mr-4" @click="simpan">Simpan</v-btn>
        </v-form>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'Setting',
    created(){
      this.$store.dispatch("checkIzinAkses",{
        namaMenu: 'Setting',
      });
      this.$store.dispatch("getSettings");
    },
    computed: {
        user() {
          return this.$store.getters.user;
        },
        tanggalMin(){
          let tanggal='';
          if (this.$store.getters.settings && this.$store.getters.settings.length > 0) {
            this.$store.getters.settings.forEach(element => {
              if (element.jenis == 'bulanKlaim') {
                tanggal= element.tanggalMin
              }
            });
          }
          return tanggal
        },
        tanggalMax(){
          let tanggal='';
          if (this.$store.getters.settings && this.$store.getters.settings.length > 0) {
            this.$store.getters.settings.forEach(element => {
              if (element.jenis == 'bulanKlaim') {
                tanggal= element.tanggalMax
              }
            });
          }
          return tanggal
        },
        tanggal: {
          get: function () {
            if (this.tanggalPicker=='') {
              return this.tanggalMin
            } else {
              return this.tanggalPicker
            }
          },
          set: function (newValue) {
            this.tanggalPicker= newValue
          }
        },
        tanggalAkhir: {
          get: function () {
            if (this.tanggalPicker2=='') {
              return this.tanggalMax
            } else {
              return this.tanggalPicker2
            }
          },
          set: function (newValue) {
            this.tanggalPicker2= newValue
          }
        },
      },
    data() {
      return {
        valid: true,
        showDateMenu: false,
        showDateMenu2: false,
        tanggalPicker: '',
        tanggalPicker2: '',
      }
    },
    methods:{
      simpan() {
        this.$refs.form.validate();
        this.$store.dispatch("updateSettings",{
          tanggalMin: this.tanggal,
          tanggalMax: this.tanggalAkhir,
        }).then(response => {
            if (response.error) {
              alert(response.message)
            } else {
              alert(response.message)
            }
        }, error => {
          alert(error)
        })
      },
    }
  }
</script>
